import { createSlice } from '@reduxjs/toolkit'

const initialValue = []

const cartSlice = createSlice({
    name: 'cart',
    initialState: initialValue,

    reducers: {
        addToCart(state, action) {
            const newItem = action.payload;

            if (Array.isArray(action.payload)) {
                const newItems = action.payload

                newItems.forEach(newItem => {
                    const existingItem = state.find(cartItem => Number(cartItem.productId) === Number(newItem.productId))

                    if (!existingItem) {
                        state.push(newItem)
                    }
                })
                //return [...state, ...action.payload];
            } else {
                const existingItem = state.find(cartItem => Number(cartItem.productId) === Number(newItem.id))
                // If it's a single item, push it into the state

                if (!existingItem) {
                    state.push(action.payload);
                }

            }
        },

        addQuantity(state, action) {
            const product = state.find(item => item.id === action.payload);

            if (product) {
                product.quantity += 1
            }
        },

        minusQuantity(state, action) {
            const product = state.find(item => item.id === action.payload)

            if (product && product.quantity > 1) {
                product.quantity -= 1
            }
        },

        removeFromCart() { }
    }
});

export const { addToCart, removeFromCart, addQuantity, minusQuantity } = cartSlice.actions

export default cartSlice.reducer