import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import Cookies from 'js-cookie';

import { useSelector, useDispatch } from 'react-redux';
import { addQuantity, minusQuantity, addToCart } from '../../redux/slices/cartSlice'

const Cart = () => {
    const dispatch = useDispatch();
    const cartItem = useSelector(state => state.cart)
    console.log(cartItem, " crtItem")
    const totalPrice = cartItem?.map(item => Number(item.price) * Number(item.quantity))?.reduce((acc, item) => acc + item, 0);

    const navigate = useNavigate();

    useEffect(() => {
        const getProducts = async () => {
            const tkn = Cookies.get('token2')
            if (tkn) {
                console.log("inside ", tkn)
                // try {
                //     const res = await axios.get("https://logsuntech.com/user/addedCartProducts",
                //         { withCredentials: true });

                //     if (res.status === 200) {
                //         console.log(res.data)
                //         dispatch(addToCart(res.data))
                //     }

                // } catch (err) {
                //     console.log(err)
                // }

            } else {
                navigate("/login")
            }

        }

        getProducts();
    }, [])

    const minusHnd = async (item) => {
        dispatch(minusQuantity(item.id))
        const qty = Number(item.quantity) - 1
        if (qty > 0) {
            try {
                const res = await axios.put(`https://logsuntech.com/user/plusQtyCart/${item.id}`,
                    { qty, name: "minus" },
                    { withCredentials: true }
                )
            } catch (err) {
                alert("Network issue")
                console.log(err)
            }

        }


    }

    const plusHnd = async (item) => {
        dispatch(addQuantity(item.id));
        const qty = Number(item.quantity) + 1

        try {
            const res = await axios.put(`https://logsuntech.com/user/plusQtyCart/${item.id}`,
                { qty, name: "plus" },
                { withCredentials: true }
            )
        } catch (err) {
            alert("Network issue")
        }
    }

    return (
        <div className='mt-24 mb-20'>
            <p className='mb-4 font-font1 text-center text-xl font-medium'>My Cart</p>

            <div className='flex flex-row gap-4 bg-gray-100'>
                <div className='w-7/12'>
                    {cartItem.map((item, index) => {
                        return <div key={index} className='bg-white flex flex-row gap-4  border border-solid border-gray-300 rounded-md mb-4'>
                            <div className='w-3/12 h-32 my-2'>
                                <div className='h-full w-full transform transition-transform duration-300 hover:scale-110 bg-cover bg-center' style={{ backgroundImage: `url(https://logsuntech.com/banner/${item.prodImages})` }}></div>
                            </div>
                            <div>
                                <p className='font-font1 text-xl '>{item.name}</p>
                                <p className='font-font1 text-xl font-medium'>Rs. {item.price}</p>

                                <div>
                                    <div className='flex flex-row gap-2'>
                                        <button onClick={() => { minusHnd(item) }} className='font-medium w-8 h-8 rounded-full border border-solid border-gray-400'>-</button>
                                        <input min={1} readOnly value={item.quantity} className='border border-solid border-gray-400 w-12 text-center'></input>
                                        <button onClick={() => { plusHnd(item) }} className='font-medium w-8 h-8 rounded-full border border-solid border-gray-400'>+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                {/* cards div */}

                {/* total div */}

                <div className='w-4/12 bg-white rounded-md font-font1 border border-solid border-gray-300 shadow'>
                    <p className='text-xl text-gray-600 font-medium m-4'>Price Details</p>
                    <p className='ml-4 text-xl font-medium'>Total Amount: Rs.{totalPrice}</p>
                </div>
            </div>
        </div>
    )
}

export default Cart;